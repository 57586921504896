<template>
  <v-container
    id="data-tables"
    tag="section"
  >
    <base-material-card
      color="indigo"
      icon="mdi-file-chart-outline"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Reports
        </div>
      </template>
      <v-row>
        <v-col cols="3" md="3" sm="12">
          <v-card>
            <v-card-text>
              <h2 class="title mb-2">
                Filter by file extension
              </h2>
              <v-chip-group
                column
                v-model="extFilter"
                @change="filterResults"
                active-class="deep-purple--text text--accent-4"
              >
                <v-chip
                  v-for="(item, i) in reportExtType"
                  :key="i"
                  filter
                  :value="item.id"
                  outlined
                >
                  <v-icon :color="item.color">{{item.icon}}</v-icon>
                  {{item.extension}}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-text>
              <h2 class="title mb-2">
                Filter by status
              </h2>
              <v-chip-group
                column
                v-model="statusFilter"
                @change="filterResults"
                active-class="deep-purple--text text--accent-4"
              >
                <v-chip
                  v-for="(item, i) in reportStatuses"
                  :key="i"
                  filter
                  :value="item.id"
                  outlined
                >
                  <v-icon :color="item.color">{{item.icon}}</v-icon>
                  {{item.status}}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9" md="9" sm="12">
          <v-toolbar
            color="indigo"
            dark
          >

            <v-text-field
              v-model="searchTerm"
              hide-details
              prepend-icon="mdi-magnify"
              single-line
              placeholder="Search for a report name or ID"
              @input="filterResults"
              clearable
              @click.clear="filterResults"
            ></v-text-field>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                large
                @click="refreshReports"
                :loading="refreshingReports"
              >
                <v-icon>mdi-cached</v-icon>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </template>
            <span>Refresh Reports</span>
          </v-tooltip>

          <v-dialog
            v-model="addReportDialog"
            persistent
            width="1000"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                large
                v-bind="attrs"
                v-on="on"
              >
                <v-icon large>mdi-plus</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title v-if="!creatingReport" class="headline">
                Create a Report
              </v-card-title>

              <v-card-title v-else class="headline">
                Creating Your Report
              </v-card-title>

              <v-card-text class="text-center">
                <ReportWizard v-if="addReportDialog && !creatingReport && !reportCreated && !reportFailed" @reportSubmitted="createReport" />
                <v-progress-linear
                  v-if="creatingReport"
                  color="light-blue"
                  height="30"
                  indeterminate
                  striped
                ></v-progress-linear>
                <div class="my-2" v-if="reportCreated">
                  <v-card-subtitle class="subtitle">
                    Your report has been submitted for creation! It may take a while to finish, check on its status using the filters!
                  </v-card-subtitle>
                  <v-btn
                    color="success"
                    icon
                    dark
                    large
                    @click="createReportClose"
                  >
                    <v-icon>mdi-check</v-icon>
                    OK!
                  </v-btn>
                </div>
                <div class="my-2" v-if="reportFailed">
                  <v-card-subtitle class="subtitle">
                    Unfortunately your report could not be submitted. Please view the errors and try again.
                  </v-card-subtitle>
                  <v-card-subtitle style="color: red" class="subtitle">
                    {{reportErrorMessageTitle}}
                  </v-card-subtitle>
                  <v-btn
                    color="success"
                    text
                    outlined
                    dark
                    large
                    @click="createReportClose"
                  >
                    Ok
                  </v-btn>
                </div>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="createReportClose"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          </v-toolbar>
          <v-container v-if="noReportsFlag && !loading">
            <v-row
              align="center"
              justify="center"
            >
              <v-col
                class="text-center"
                cols="12"
              >
              <v-alert
                outlined
                color="indigo lighten-1"
                icon="mdi-information-outline"
                prominent
              >
                <h1 class="text-h1 font-weight-thick mb-4">
                  No Reports Available
                </h1>
                <h4 class="subheading text-h3">
                  Create a report to view custom data sets from your portal, and manage them here!
                </h4>
              </v-alert>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="loading">
            <v-row>
              <v-col
                v-for="n in 9"
                :key="n"
                :cols="4"
              >
                <v-skeleton-loader
                  type="article, actions"
                  :loading="loading"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="!noReportsFlag && !loading" fluid>
            <v-row dense>
              <v-col
                v-for="report in reports"
                :key="report.id"
                :cols="4"
              >
                <template>
                  <v-card
                    class="mx-auto"
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="overline mb-4">
                          {{report.id}}
                        </div>
                        <v-list-item-title class="headline mb-1" v-text="report.name"></v-list-item-title>
                        <v-list-item-subtitle>{{report.statusDescription}}</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar tile size="50">
                        <v-list-item-icon>
                          <v-icon large color="red darken-1" v-if="report.fileType === '.pdf'">mdi-file-pdf-box</v-icon>
                          <v-icon large color="green darken-1" v-if="report.fileType === '.xlsx'">mdi-microsoft-excel</v-icon>
                          <v-icon large color="yellow darken-1" v-if="report.fileType === '.json'">mdi-code-json</v-icon>
                        </v-list-item-icon>
                      </v-list-item-avatar>
                    </v-list-item>

                    <v-card-actions>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="!statusLoading"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            :color="report.color"
                            v-text="report.icon"
                          >
                          </v-icon>
                        </template>
                        <span>{{report.status}}</span>
                      </v-tooltip>

                      <v-spacer></v-spacer>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" @click="getDownloadLink(report.id)" :disabled="report.status !== 'Complete'" icon>
                            <v-icon
                              color="green darken-1"
                            >
                              mdi-download
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Download this report. Link expires in 60s</span>
                      </v-tooltip>

                      <v-spacer></v-spacer>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click.stop="deleteConfirm(report.id)" icon v-bind="attrs" v-on="on">
                            <v-icon
                              color="error"
                            >
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                      <span>Delete</span>
                      </v-tooltip>

                      <v-dialog
                        :retain-focus="false"
                        v-model="deleteConfirmDialog"
                        persistent
                        width="1000"
                      >
                        <v-card>
                          <v-card-title class="headline">Are you sure?</v-card-title>
                          <v-card-text>
                            This report will be permanently deleted, and cannot be recovered. If this report was created using a preset, the preset will not be deleted.
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              v-on:click.stop="deleteConfirmDialog = false"
                            >
                              No, don't delete
                            </v-btn>
                            <v-btn
                              color="red darken-1"
                              v-on:click.stop="deleteReport()"
                            >
                            Yes, delete report
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                    </v-card-actions>
                  </v-card>
                </template>
              </v-col>
            </v-row>
            <div class="text-center pt-2">
              <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || snackColor === 'info'" color="blue darken-1" v-on:click="previousPage()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || snackColor === 'info'" color="blue darken-1" v-on:click="nextPage()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </base-material-card>
    <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
      <v-progress-circular
        v-if="snackColor === 'info'"
        color="white"
        indeterminate
      ></v-progress-circular>
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import Reports from '@/Services/Reports/GetReports'
import ReportFilters from '@/Services/Reports/ReportFilters'
// import Presets from '@/Services/Reports/Presets'
import ReportWizard from '@/views/dashboard/forms/ReportWizard'
import CreateReport from '@/Services/Reports/CreateReport'
import DeleteReport from '@/Services/Reports/DeleteReport'
export default {
  name: 'Reports',
  components: {
    ReportWizard
  },
  data: () => {
    return {
      reports: [],
      reportTypes: [],
      reportExtType: [],
      reportStatuses: [],
      availablePresets: [],
      typeFilter: [],
      extFilter: null,
      statusFilter: null,
      rowsPerPage: 9,
      messages: 0,
      dateOptions: [{
        value: 1,
        text: 'Today'
      },
      {
        value: 2,
        text: 'Yesterday'
      },
      {
        value: 3,
        text: 'Last 7 Days'
      },
      {
        value: 4,
        text: 'Last 28 Days'
      },
      {
        value: 5,
        text: 'Custom Date Range'
      }
      ],
      dateMenu: false,
      dates: [],
      searchURL: null,
      searchTerm: '',
      reportDownloadLink: '',
      nextCursor: '',
      prevCursor: '',
      snack: false,
      snackTimeout: null,
      snackColor: null,
      snackText: '',
      addReportDialog: false,
      deleteConfirmDialog: false,
      deleteReportID: null,
      creatingReport: false,
      reportCreated: false,
      reportFailed: false,
      refreshingReports: false,
      statusLoading: false,
      timer: null,
      noReportsFlag: false,
      loading: false,
      reportErrorMessageTitle: '',
      currentPage: null
    }
  },
  created () {
    this.initialise()
  },
  mounted: function () {
    this.timer = setInterval(() => {
      this.refreshReports()
    }, 15000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    async initialise () {
      this.loading = true
      this.snackLoading()
      await Reports.getReports(this.rowsPerPage)
        .then((res) => {
          if (res.status === 200) {
            this.noReportsFlag = false
            this.$set(this, 'reports', res.data.results)
            this.nextCursor = res.data.nextCursor
            this.prevCursor = res.data.previousCursor
            this.snackSuccess()
            this.loading = false
          } else if (res.status === 404) {
            this.$set(this, 'reports', [])
            this.nextCursor = ''
            this.prevCursor = ''
            this.noReportsFlag = true
            this.snack = false
            this.loading = false
          } else {
            // eslint-disable-next-line
            console.error(res)
            this.snackError('Could not load reports')
            this.loading = false
          }
        })
      await ReportFilters.reportTypes()
        .then((res) => {
          this.$set(this, 'reportTypes', res.data)
        })
      await ReportFilters.fileExt()
        .then((res) => {
          this.$set(this, 'reportExtType', res.data)
          this.reportExtType.forEach(element => {
            switch (element.extension) {
              case '.pdf':
                element.color = 'red darken-1'
                element.icon = 'mdi-file-pdf-box'
                break
              case '.xlsx':
                element.color = 'green darken-1'
                element.icon = 'mdi-microsoft-excel'
                break
              case '.json':
                element.color = 'yellow darken-1'
                element.icon = 'mdi-code-json'
            }
          })
        })
      await ReportFilters.reportStatus()
        .then((res) => {
          this.$set(this, 'reportStatuses', res.data)
          this.reportStatuses.forEach(element => {
            switch (element.id) {
              case 1:
                element.color = 'orange darken-1'
                element.icon = 'mdi-tray-full'
                break
              case 2:
                element.color = 'info'
                element.icon = 'mdi-file-sync'
                break
              case 3:
                element.color = 'indigo'
                element.icon = 'mdi-file-compare'
                break
              case 4:
                element.color = 'green darken-2'
                element.icon = 'mdi-file-code'
                break
              case 5:
                element.color = 'yellow darken-1'
                element.icon = 'mdi-file-upload'
                break
              case 6:
                element.color = 'success'
                element.icon = 'mdi-file-check'
                break
              case 7:
                element.color = 'error'
                element.icon = 'mdi-file-alert'
                break
              case 8:
                element.color = 'purple darken-1'
                element.icon = 'mdi-file-clock'
            }
          })
        })
      this.assignStatus()
    },
    async refreshReports () {
      this.refreshingReports = true
      await Reports.refreshReports(this.rowsPerPage, this.searchURL, this.currentPage)
        .then((res) => {
          if (res.status === 200) {
            this.noReportsFlag = false
            this.$set(this, 'reports', res.data.results)
            this.nextCursor = res.data.nextCursor
            this.prevCursor = res.data.previousCursor
            this.refreshingReports = false
          } else if (res.status === 404) {
            this.$set(this, 'reports', [])
            this.refreshingReports = false
            this.nextCursor = ''
            this.prevCursor = ''
            this.noReportsFlag = true
          }
        })
      this.assignStatus()
    },
    async filterResults () {
      this.loading = true
      const term = this.searchTerm
      const status = this.statusFilter
      const ext = this.extFilter
      let url = ''
      if (term) {
        url = url + '&search=' + term
      }
      if (status) {
        url = url + '&status=' + status
      }
      if (ext) {
        url = url + '&extension=' + ext
      }
      if (url !== null) {
        this.searchURL = '?count=' + this.rowsPerPage + url
      } else if (url === null) {
        this.searchURL = '?count=' + this.rowsPerPage
      }
      await Reports.filterReports(this.rowsPerPage, url)
        .then((res) => {
          if (res.status === 200) {
            this.$set(this, 'reports', res.data.results)
            this.noReportsFlag = false
            this.nextCursor = res.data.nextCursor
            this.prevCursor = res.data.previousCursor
            this.assignStatus()
            this.loading = false
          } else {
            this.$set(this, 'reports', [])
            this.loading = false
            this.noReportsFlag = true
          }
        })
    },
    async createReport (reportDetails) {
      this.creatingReport = true
      await CreateReport.createReport(reportDetails)
        .then((res) => {
          if (res.status === 202) {
            this.creatingReport = false
            this.reportCreated = true
            this.refreshReports()
          } else {
            if (res.data.errors && res.data.title) {
              this.$set(this, 'reportErrorMessageTitle', res.data.title)
            } else {
              this.reportErrorMessageTitle = 'SERVER ERROR'
            }
            this.reportFailed = true
            this.creatingReport = false
          }
        })
    },
    deleteConfirm (id) {
      this.deleteReportID = id
      this.deleteConfirmDialog = true
    },
    async deleteReport () {
      await DeleteReport.deleteReport(this.deleteReportID)
        .then((res) => {
          if (res.status === 200) {
            this.deleteConfirmDialog = false
            this.refreshReports()
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Report Deleted Successfully!'
            this.snackTimeout = 3000
          } else {
            this.deleteConfirmDialog = false
            this.refreshReports()
            this.snackError('Report could not be deleted.')
          }
        })
    },
    createReportClose () {
      this.addReportDialog = false
      this.reportCreated = false
      this.creatingReport = false
      this.reportFailed = false
    },
    async getDownloadLink (id) {
      await Reports.createDownloadLink(id)
        .then((res) => {
          if (res.status === 200) {
            this.reportDownloadLink = res.data.url
            window.open(this.reportDownloadLink, '_blank')
          } else if (res.status === 404) {
            this.snack = true
            this.snackText = 'Could not find requested report'
            this.snackColor = 'error'
            this.snackTimeout = 5000
          }
        })
    },
    async previousPage () {
      this.loading = true
      await Reports.getPreviousPage(this.rowsPerPage, this.searchURL, this.prevCursor)
        .then((response) => {
          if (response.status === 200) {
            this.currentPage = '&previous=' + this.prevCursor
            this.$set(this, 'reports', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.assignStatus()
            this.loading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get previous results'
            this.snackTimeout = 5000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async nextPage () {
      this.loading = true
      await Reports.getNextPage(this.rowsPerPage, this.searchURL, this.nextCursor)
        .then((response) => {
          if (response.status === 200) {
            this.currentPage = '&next=' + this.nextCursor
            this.$set(this, 'reports', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.assignStatus()
            this.loading = false
          } else {
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get next results'
            this.snackTimeout = 5000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    assignStatus () {
      this.statusLoading = true
      this.reports.forEach(element => {
        const index = this.reportStatuses.findIndex(i => i.status === element.status)
        if (index !== -1) {
          element.icon = this.reportStatuses[index].icon
          element.color = this.reportStatuses[index].color
        }
      })
      this.statusLoading = false
    },
    snackLoading () {
      this.snack = true
      this.snackTimeout = -1
      this.snackColor = 'info'
      this.snackText = 'Loading Reports...'
    },
    snackSuccess () {
      this.snackTimeout = 3000
      this.snackColor = 'success'
      this.snackText = 'Reports Loaded'
    },
    snackError (errorMessage) {
      this.snackTimeout = 5000
      this.snackColor = 'error'
      this.snackText = errorMessage
    }
  }
}
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
