<template>
  <v-container
    id="wizard"
    tag="section"
  >
    <validation-observer ref="observer" v-slot="{ valid }">
      <base-material-wizard
        v-model="tab"
        :available-steps="availableSteps"
        title=""
        subtitle=""
        :items="tabs"
        :iconsText="true"
        :valid="valid"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="back(valid)"
      >
        <v-tab-item class="pb-12">
          <form @submit.prevent>
            <v-responsive
              class="mx-auto"

              max-width="500"
            >
              <div class="text-center display-1 grey--text font-weight-light mb-6">
                Choose from a preset report, or create your own
              </div>

              <v-item-group
                v-model="reportPreset"
                class="row"
              >
                <v-item
                  v-for="(type, i) in reportPresets"
                  :key="i"
                  :value="type.type"
                >
                  <template v-slot="{ active, toggle }">
                    <v-col
                      class="text-center"
                      cols="6"
                    >
                      <v-card
                        :class="active ? 'success--text' : 'grey--text'"
                        class="mb-6 mx-auto pa-10 d-inline-block v-card--account"
                        outlined
                        @click="toggle"
                      >
                        <v-icon
                          large
                          v-text="type.icon"
                        />
                      </v-card>

                      <div
                        class="text-uppercase subtitle-2 text--primary"
                        v-text="type.type"
                      />
                    </v-col>
                  </template>
                </v-item>
              </v-item-group>
            </v-responsive>
          </form>
        </v-tab-item>

        <v-tab-item class="pb-12">
          <form @submit.prevent>
            <v-row
              class="mx-auto"
              justify="space-around"
              style="max-width: 500px;"
              v-if="reportPreset === 'preset'"
            >

              <v-col
                cols="12"
                md="6"
              >
                <v-menu v-if="selectedFilter === 5" ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates" transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary" dark v-bind="attrs" v-on="on">
                      Select a Range
                    </v-btn>
                  </template>
                  <v-date-picker v-model="dates" scrollable range>
                    <v-spacer></v-spacer>
                    <v-btn text color="secondary" @click="menu = false">Cancel</v-btn>
                    <v-btn :disabled="dates.length <= 1" text color="primary" @click="getCustomDates">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu>
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn color="secondary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }" class="float-right">
                          {{dateRangeText}}
                          <v-icon right dark>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <span>Select a date range</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in filterOptions" :key="index" @click="getPreSetDates(item.value)">
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="selectedPreset"
                >
                  <v-autocomplete
                    color="secondary"
                    :error-messages="errors"
                    v-model="chosenPreset"
                    :items="availablePresets"
                    :loading="isLoading"
                    :search-input="selectedPreset"
                    outlined
                    chips
                    small-chips
                    deletable-chips
                    item-text="presetName"
                    item-value="id"
                    hide-no-data
                    return-object
                    label="Select a preset to create your report"
                    placeholder="Start typing to Search"
                    append-icon="mdi-database-search"
                    @change="getPresetValues"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeSelectedPreset(data.item)"
                      >
                        {{ data.item.presetName }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.presetName"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="name"
                >
                  <v-text-field
                    v-model="name"
                    :error-messages="errors"
                    outlined
                    color="secondary"
                    label="Name"
                    validate-on-blur
                  />
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Report Extension"
                >
                  <v-autocomplete
                    color="secondary"
                    v-model="fileType"
                    :items="reportExtOptions"
                    :loading="isLoading"
                    :search-input.sync="reportExtSearch"
                    :error-messages="errors"
                    outlined
                    chips
                    small-chips
                    deletable-chips
                    item-text="extension"
                    item-value="id"
                    hide-no-data
                    label="Select report extension type"
                    placeholder="Start typing to Search"
                    append-icon="mdi-database-search"
                    v-on:input="reportExtSearch = null"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        :color="data.item.color"
                        text-color="white"
                        @click="data.select"
                        @click:close="removeExt(data.item)"
                      >
                        <v-avatar left>
                          <v-icon v-text="data.item.icon"></v-icon>
                        </v-avatar>
                        {{ data.item.extension }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-avatar>
                          <v-icon v-text="data.item.icon" :color="data.item.color"></v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.extension"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
              >
                <v-autocomplete
                  readonly
                  color="secondary"
                  v-model="reportTypes"
                  :items="reportTypesOptions"
                  :loading="isLoading"
                  :search-input.sync="reportTypeSearch"
                  outlined
                  chips
                  small-chips
                  item-text="name"
                  item-value="id"
                  hide-no-data
                  multiple
                  return-object
                  label="Select report types"
                  placeholder="Start typing to Search"
                  append-icon="mdi-database-search"
                  v-on:input="checkFieldRequirements"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" v-if="termShow">

                <validation-provider
                  v-if="termRequired"
                  v-slot="{ errors }"
                  rules="required"
                  name="Report Extension"
                >
                  <v-autocomplete
                    readonly
                    :error-messages="errors"
                    v-model="terms"
                    :items="tagOptions"
                    :loading="isTermLoading"
                    :search-input.sync="searchTags"
                    outlined
                    chips
                    small-chips
                    item-text="tagName"
                    item-value="id"
                    hide-no-data
                    multiple
                    label="Available Tags"
                    placeholder="Start typing to Search"
                    append-icon="mdi-database-search"
                    v-on:input="searchTags = null"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                      >
                        {{ data.item.tagName }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.tagName"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </validation-provider>

                <validation-provider
                  v-else
                  v-slot="{ errors }"
                  rules=""
                  name="Report Extension"
                >
                  <v-autocomplete
                    readonly
                    :error-messages="errors"
                    v-model="terms"
                    :items="tagOptions"
                    :loading="isTermLoading"
                    :search-input.sync="searchTags"
                    outlined
                    chips
                    small-chips
                    deletable-chips
                    item-text="tagName"
                    item-value="id"
                    hide-no-data
                    multiple
                    label="Available Tags"
                    placeholder="Start typing to Search"
                    append-icon="mdi-database-search"
                    v-on:input="searchTags = null"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeTag(data.item)"
                      >
                        {{ data.item.tagName }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.tagName"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>

              <v-col cols="12" v-if="locationShow && !location">
                <LocationSearch :outlined="true" @fullLocationEmit="saveLocation" @locationRemoved="removeLocation" />
              </v-col>

              <v-col
                cols="12"
                md="6"
                v-if="locationShow && location"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                >
                  <v-text-field
                    v-model="location"
                    readonly
                    :error-messages="errors"
                    clearable
                    outlined
                    color="secondary"
                    label="Location"
                    validate-on-blur
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row
              class="mx-auto"
              justify="space-around"
              style="max-width: 500px;"
              v-if="reportPreset === 'new'"
            >
              <v-col
                cols="12"
                md="6"
              >
                <v-menu v-if="selectedFilter === 5" ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates" transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary" dark v-bind="attrs" v-on="on">
                      Select a Range
                    </v-btn>
                  </template>
                  <v-date-picker v-model="dates" scrollable range>
                    <v-spacer></v-spacer>
                    <v-btn text color="secondary" @click="menu = false">Cancel</v-btn>
                    <v-btn :disabled="dates.length <= 1" text color="primary" @click="getCustomDates">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu>
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn color="secondary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }" class="float-right">
                          {{dateRangeText}}
                          <v-icon right dark>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <span>Select a date range</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in filterOptions" :key="index" @click="getPreSetDates(item.value)">
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="name"
                >
                  <v-text-field
                    v-model="name"
                    :error-messages="errors"
                    outlined
                    color="secondary"
                    label="Name*"
                    validate-on-blur
                  />
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Report Extension"
                >
                  <v-autocomplete
                    color="secondary"
                    v-model="fileType"
                    :items="reportExtOptions"
                    :loading="isLoading"
                    :search-input.sync="reportExtSearch"
                    :error-messages="errors"
                    outlined
                    chips
                    small-chips
                    deletable-chips
                    item-text="extension"
                    item-value="id"
                    hide-no-data
                    label="Select report extension type"
                    placeholder="Start typing to Search"
                    append-icon="mdi-database-search"
                    v-on:input="reportExtSearch = null"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        :color="data.item.color"
                        text-color="white"
                        @click="data.select"
                        @click:close="removeExt(data.item)"
                      >
                        <v-avatar left>
                          <v-icon v-text="data.item.icon"></v-icon>
                        </v-avatar>
                        {{ data.item.extension }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-avatar>
                          <v-icon v-text="data.item.icon" :color="data.item.color"></v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.extension"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Report Extension"
                >
                  <v-autocomplete
                    color="secondary"
                    :error-messages="errors"
                    v-model="reportTypes"
                    :items="reportTypesOptions"
                    :loading="isLoading"
                    :search-input.sync="reportTypeSearch"
                    outlined
                    chips
                    small-chips
                    deletable-chips
                    item-text="name"
                    item-value="id"
                    hide-no-data
                    multiple
                    return-object
                    label="Select report types"
                    placeholder="Start typing to Search"
                    append-icon="mdi-database-search"
                    v-on:input="checkFieldRequirements"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeType(data.item)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>

              <v-col cols="12" v-if="termShow">

                <validation-provider
                  v-if="termRequired"
                  v-slot="{ errors }"
                  rules="required"
                  name="Report Extension"
                >
                  <v-autocomplete
                    :error-messages="errors"
                    v-model="terms"
                    :items="tagOptions"
                    :loading="isTermLoading"
                    :search-input.sync="searchTags"
                    outlined
                    chips
                    small-chips
                    deletable-chips
                    item-text="tagName"
                    item-value="id"
                    hide-no-data
                    multiple
                    label="Available Tags"
                    placeholder="Start typing to Search"
                    append-icon="mdi-database-search"
                    v-on:input="searchTags = null"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeTag(data.item)"
                      >
                        {{ data.item.tagName }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.tagName"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </validation-provider>

                <validation-provider
                  v-else
                  v-slot="{ errors }"
                  rules=""
                  name="Report Extension"
                >
                  <v-autocomplete
                    :error-messages="errors"
                    v-model="terms"
                    :items="tagOptions"
                    :loading="isTermLoading"
                    :search-input.sync="searchTags"
                    outlined
                    chips
                    small-chips
                    deletable-chips
                    item-text="tagName"
                    item-value="id"
                    hide-no-data
                    multiple
                    label="Available Tags"
                    placeholder="Start typing to Search"
                    append-icon="mdi-database-search"
                    v-on:input="searchTags = null"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeTag(data.item)"
                      >
                        {{ data.item.tagName }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.tagName"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>

              <v-col cols="12" v-if="locationShow">
                <LocationSearch :outlined="true" @fullLocationEmit="saveLocation" @locationRemoved="removeLocation" />
              </v-col>
            </v-row>
          </form>
        </v-tab-item>

        <v-tab-item class="pb-12">
          <form @submit.prevent>
            <v-row
              class="mx-auto"
              justify="space-around"
              style="max-width: 500px;"
            >
              <v-col cols="12">
                <v-switch
                  v-model="scheduled"
                  color="green"
                  label="Would you like to schedule this report?"
                ></v-switch>
                <v-expand-transition v-if="scheduled">
                  <div v-show="scheduled">
                    <v-card-text>This report will be delivered to the email address entered at the chosen time.</v-card-text>
                    <v-divider></v-divider>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|email"
                        name="email"
                      >
                        <v-text-field
                          v-model="scheduledEmail"
                          :error-messages="errors"
                          color="secondary"
                          label="Email*"
                          prepend-icon="mdi-email"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>
                    <v-row>
                      <v-col
                        cols="11"
                        lg="5"
                      >
                        <v-menu
                          ref="menu1"
                          v-model="scheduledDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateFormatted"
                              label="Date"
                              hint="Select the date you wish to receive this report."
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              @blur="date = parseDate(dateFormatted)"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            no-title
                            @input="scheduledDateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col
                        cols="11"
                        lg="5"
                      >
                        <v-menu
                          ref="menu"
                          v-model="scheduledTimeMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="time"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="time"
                              label="Select a time"
                              hint="Select the time you wish to create this report"
                              persistent-hint
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="scheduledTimeMenu"
                            v-model="time"
                            full-width
                            @click:minute="$refs.menu.save(time)"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
              </v-col>
            </v-row>
          </form>
        </v-tab-item>

        <v-tab-item class="pb-12">
          <div v-if="reportPreset === 'new'" class="text-center display-1 grey--text font-weight-light mb-6">
            Save your report as a new preset (optional), and submit your report for creation
          </div>

          <div v-if="reportPreset === 'preset'" class="text-center display-1 grey--text font-weight-light mb-6">
            Create your report
          </div>

          <form @submit.prevent v-if="reportPreset === 'new'">
            <v-row
              class="mx-auto"
              justify="space-around"
              style="max-width: 500px;"
            >
            </v-row>
              <v-col cols="12">
                <v-switch
                  v-model="save"
                  color="green"
                  label="Would you like to save this report as a preset?"
                ></v-switch>
                <v-expand-transition v-if="save">
                  <div v-show="save">
                    <v-card-text>Name your preset and use it later for future reports!</v-card-text>
                    <v-divider></v-divider>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="presetName"
                      >
                        <v-text-field
                          v-model="presetName"
                          :error-messages="errors"
                          color="secondary"
                          label="presetName*"
                          prepend-icon="mdi-edit"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>
                  </div>
                </v-expand-transition>
              </v-col>
          </form>
        </v-tab-item>

      </base-material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import ReportFilters from '@/Services/Reports/ReportFilters'
import LocationSearch from '@/views/dashboard/component/LocationSearch'
import CreateReport from '@/Services/Reports/CreateReport'
export default {
  name: 'ReportCreationForm',
  components: {
    LocationSearch
  },
  data: vm => ({
    isLoading: false,
    isTermLoading: false,
    reportPreset: '',
    reportPresets: [
      {
        icon: 'mdi-book-alphabet',
        type: 'preset'
      },
      {
        icon: 'mdi-book-plus',
        type: 'new'
      }
    ],
    availablePresets: [],
    name: '',
    filterType: null,
    startDate: '',
    endDate: '',
    reportTypesOptions: [],
    reportTypeSearch: '',
    reportTypes: [],
    reportExtSearch: '',
    reportExtOptions: [],
    terms: [],
    districtID: null,
    location: '',
    fileType: null,
    dates: [],
    menu: false,
    selectedFilter: null,
    filterOptions: [{
      value: null,
      text: 'Select a date range'
    },
    {
      value: 1,
      text: 'Today'
    },
    {
      value: 2,
      text: 'Yesterday'
    },
    {
      value: 3,
      text: 'Last 7 Days'
    },
    {
      value: 4,
      text: 'Last 28 Days'
    },
    {
      value: 5,
      text: 'Custom Date Range'
    }
    ],
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    time: null,
    scheduledTimeMenu: false,
    scheduledDateMenu: false,
    scheduled: false,
    scheduledTime: null,
    scheduledEmail: '',
    presetID: null,
    chosenPreset: {},
    selectedPreset: null,
    locationShow: false,
    locationRequired: false,
    termShow: false,
    termRequired: false,
    searchTags: '',
    tagOptions: [],
    save: false,
    presetName: '',
    tab: 0,
    tabs: [
      { name: 'Report Type', icon: 'mdi-file-cog' },
      { name: 'Report Details', icon: 'mdi-information-outline' },
      { name: 'Schedule Report (optional)', icon: 'mdi-clock-outline' },
      { name: 'Save & Create', icon: 'mdi-content-save-outline' }
    ]
  }),
  mounted () {
    this.getPresets()
  },
  watch: {
    async searchTags (val) {
      // Items have already been loaded
      if (this.tagOptions.length > 0) return
      // Items have already been requested
      if (this.isTermLoading) return
      this.isTermLoading = true
      // Lazily load input items
      await CreateReport.getTags()
        .then((res) => {
          if (res.status === 200) {
            this.tagOptions = res.data
            this.isTermLoading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load tags. Error: ' + res.data.title
            this.snackTimeout = 5000
            this.isTermLoading = false
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err)
        })
    },
    async reportTypeSearch (val) {
      // Items have already been loaded
      if (this.reportTypesOptions.length > 0) return
      // Items have already been requested
      // if (this.isLoading) return
      // this.isLoading = true
      // Lazily load input items
      await ReportFilters.reportTypes()
        .then((res) => {
          if (res.status === 200) {
            this.reportTypesOptions = res.data
            // this.isLoading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load types. Error: ' + res.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((err) => {
          // this.isLoading = false
          // eslint-disable-next-line
          console.error(err)
        })
    },
    async reportExtSearch (val) {
      // Items have already been loaded
      if (this.reportExtOptions.length > 0) return
      // Items have already been requested
      // if (this.isLoading) return
      // this.isLoading = true
      // Lazily load input items
      await ReportFilters.fileExt()
        .then((res) => {
          if (res.status === 200) {
            this.reportExtOptions = res.data
            this.reportExtOptions.forEach(element => {
              switch (element.extension) {
                case '.pdf':
                  element.color = 'red darken-1'
                  element.icon = 'mdi-file-pdf-box'
                  break
                case '.xlsx':
                  element.color = 'green darken-1'
                  element.icon = 'mdi-microsoft-excel'
                  break
                case '.json':
                  element.color = 'yellow darken-1'
                  element.icon = 'mdi-code-json'
                  // this.isLoading = false
              }
            })
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load tags. Error: ' + res.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err)
        })
    },
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    }
  },

  computed: {
    scope () {
      if (this.tab === 0) return 'Report Type'
      else if (this.tab === 1) return 'Report Details'
      else if (this.tab === 3) return 'Save & Create'
      return 'Schedule Report (optional)'
    },
    dateRangeText () {
      let dateText
      if (this.selectedFilter === 5) {
        dateText = this.dates.join(' ~ ')
      } else {
        this.filterOptions.forEach(element => {
          if (element.value !== 5 && element.value === this.selectedFilter) {
            dateText = element.text
          }
        })
      }
      return dateText
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    availableSteps () {
      const steps = [0]

      if (this.reportPreset) {
        steps.push(1)
      }
      if (this.locationRequired) {
        if (
          steps.includes(1) &&
          this.filterType &&
          this.name &&
          this.fileType &&
          this.reportTypes.length !== 0 &&
          this.districtID
        ) steps.push(2)
      } else if (this.termRequired) {
        if (
          steps.includes(1) &&
          this.filterType &&
          this.name &&
          this.fileType &&
          this.reportTypes.length !== 0 &&
          this.terms.length !== 0
        ) steps.push(2)
      } else if (this.termRequired && this.locationRequired) {
        if (
          steps.includes(1) &&
          this.filterType &&
          this.name &&
          this.fileType &&
          this.reportTypes.length !== 0 &&
          this.districtID &&
          this.terms.length !== 0
        ) steps.push(2)
      } else {
        if (
          steps.includes(1) &&
          this.filterType &&
          this.name &&
          this.fileType &&
          this.reportTypes.length !== 0
        ) steps.push(2)
      }

      if (
        steps.includes(2) &&
        this.scheduled &&
        this.scheduledEmail &&
        this.date &&
        this.time
      ) steps.push(3)

      if (
        steps.includes(2) &&
        !this.scheduled
      ) steps.push(3)

      if (
        this.save === true &&
        this.presetName &&
        steps.includes(3)
      ) steps.push(4)

      if (
        !this.save &&
        steps.includes(3)
      ) steps.push(4)

      return steps
    }
  },

  methods: {
    back (valid) {
      if (!valid) return

      if (valid) {
        this.tab--
      }
    },
    next (valid) {
      if (!valid) return

      if (this.tab === this.tabs.length - 1) {
        const reportDetails = {
          name: '',
          filterType: null,
          startDate: '',
          endDate: '',
          reportTypes: [],
          terms: [],
          districtID: null,
          location: '',
          fileType: null,
          scheduled: false,
          scheduledEmail: '',
          scheduledTime: '',
          save: false,
          presetName: ''
        }
        reportDetails.name = this.name
        reportDetails.filterType = this.filterType
        reportDetails.startDate = this.startDate
        reportDetails.endDate = this.endDate
        this.reportTypes.forEach((element) => {
          reportDetails.reportTypes.push(element.id)
        })
        reportDetails.terms = this.terms
        reportDetails.districtID = this.districtID
        reportDetails.location = this.location
        reportDetails.fileType = this.fileType
        if (this.scheduled === true) {
          reportDetails.scheduled = true
          reportDetails.scheduledTime = this.date + ' ' + this.time
          reportDetails.scheduledEmail = this.scheduledEmail
        }
        if (this.save === true) {
          reportDetails.save = true
          reportDetails.presetName = this.presetName
        }
        this.$emit('reportSubmitted', reportDetails)
      } else {
        this.tab++
      }
    },
    async getPresets () {
      await ReportFilters.loadPresets()
        .then((res) => {
          if (res.status === 200) {
            this.$set(this, 'availablePresets', res.data.results)
            // this.isLoading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load presets. Error: ' + res.data.title
            this.snackTimeout = 5000
          }
        })
    },
    getPreSetDates (value) {
      this.selectedFilter = value
      this.dates = []
      if (this.selectedFilter !== 5) {
        this.filterType = this.selectedFilter
      }
    },
    async getCustomDates () {
      this.$refs.menu.save(this.dates)
      this.menu = false
      if (this.selectedFilter === 5) {
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          this.filterType = this.selectedFilter
          this.startDate = startDate
          this.endDate = endDate
        } else {
          this.errorSnack()
        }
      }
    },
    async getPresetValues () {
      this.resetData()
      const presetDetails = this.chosenPreset
      if (Object.keys(presetDetails).length !== 0) {
        this.districtID = presetDetails.districtID
        this.location = presetDetails.location
        await ReportFilters.reportTypes()
          .then((res) => {
            if (res.status === 200) {
              const allPresets = res.data
              if (presetDetails) {
                presetDetails.reportTypes.forEach((element) => {
                  const index = allPresets.findIndex(i => i.id === element)
                  this.reportTypes.push(allPresets[index])
                })
              }
            }
          })
        this.terms = presetDetails.terms
        this.checkFieldRequirements()
      } else {
        this.checkFieldRequirements()
      }
    },
    checkFieldRequirements () {
      this.reportTypeSearch = null
      const multipleIDArr = []
      let requiredFieldID
      if (this.reportTypes.length === 1) {
        requiredFieldID = this.reportTypes[0].required
      } else if (this.reportTypes.length >= 1) {
        this.reportTypes.forEach((element) => {
          multipleIDArr.push(element.required)
        })
        multipleIDArr.sort((a, b) => b - a)
        requiredFieldID = multipleIDArr[0]
      } else {
        this.locationShow = false
        this.locationRequired = false
        this.termShow = false
        this.termRequired = false
      }
      switch (requiredFieldID) {
        case null:
          this.locationShow = false
          this.locationRequired = false
          this.termShow = false
          this.termRequired = false
          break
        case 1:
          this.locationShow = true
          this.locationRequired = false
          this.termShow = false
          this.termRequired = false
          break
        case 2:
          this.locationShow = true
          this.locationRequired = false
          this.termShow = true
          this.termRequired = true
          break
        case 3:
          this.locationShow = true
          this.locationRequired = true
          this.termShow = false
          this.termRequired = false
          break
        case 4:
          this.locationShow = true
          this.locationRequired = true
          this.termShow = true
          this.termRequired = true
          break
        default:
          this.locationShow = false
          this.locationRequired = false
          this.termShow = false
          this.termRequired = false
      }
    },
    resetData () {
      this.name = ''
      this.filterType = null
      this.selectedFilter = null
      this.fileType = null
      this.reportTypes = []
      this.location = ''
      this.districtID = null
      this.terms = []
      this.scheduled = false
      this.scheduledEmail = ''
      this.date = new Date().toISOString().substr(0, 10)
      this.time = null
      this.save = false
      this.presetName = ''
    },
    removeType (item) {
      const index = this.reportTypes.findIndex(i => i.name === item.name)
      if (index >= 0) this.reportTypes.splice(index, 1)
      this.checkFieldRequirements()
    },
    removeTag (item) {
      const index = this.terms.findIndex(i => i === item.id)
      if (index >= 0) this.terms.splice(index, 1)
    },
    removeSelectedPreset (item) {
      this.chosenPreset = {}
      this.presetID = null
      this.getPresetValues()
      this.checkFieldRequirements()
    },
    removeExt () {
      this.fileType = null
    },
    saveLocation (value) {
      this.location = value.name
      this.districtID = value.districtID
    },
    removeLocation (value) {
      this.location = null
      this.districtID = null
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
}
</script>

<style lang="sass">
  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%

</style>
